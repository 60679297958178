import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "addArticle",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
    },
    component: () => import(/* webpackChunkName: "all" */ "../views/HomeView.vue"),
  },
  {
    path: "/remove-item",
    name: "removeArticle",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
    },
    component: () =>
      import(/* webpackChunkName: "all" */ "../views/RemoveItemView.vue"),
  },
  {
    path: "/group-picking",
    name: "groupPicking",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
    },
    component: () =>
      import(/* webpackChunkName: "all" */ "../views/GroupPickingView.vue"),
    children: [
      {
        path: "",
        name: "group-picking-overview",
        meta: {
          parent: "groupPicking",
        },
        component: () =>
          import(/* webpackChunkName: "all" */ "../views/group-picking/GroupPickingOverview.vue"),
      },
      {
        path: ":id",
        name: "group-picking-stock",
        meta: {
          parent: "groupPicking",
        },
        component: () => import(/* webpackChunkName: "all" */ "../views/group-picking/GroupPickingStock.vue"),
      },
      {
        path: ":id/group-picking-start",
        name: "group-picking-start",
        meta: {
          parent: "groupPicking",
        },
        component: () => import(/* webpackChunkName: "all" */ "../views/group-picking/GroupPickingStart.vue"),
      },
      {
        path: ":id/group-picking-start/:index/item-pick",
        name: "group-picking-item-pick",
        meta: {
          parent: "groupPicking",
        },
        component: () =>
          import(/* webpackChunkName: "all" */ "../views/group-picking/GroupPickingItemPick.vue"),
      },
    ],
  },
  {
    path: "/cockpit",
    name: "cockpit",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
    },
    children: [
      {
        path: "warehouse-overview",
        name: "warehouseOverview",
        meta: {
          parent: "cockpit",
        },
        component: () =>
            import(/* webpackChunkName: "all" */ "../views/WarehouseOverviewView.vue"),
      },
      {
        path: "article-overview",
        name: "articleOverview",
        meta: {
          parent: "cockpit",
        },
        component: () => import(/* webpackChunkName: "all" */ "../views/cockpit/CockpitArticleOverview.vue"),
      },
    ],
  },
  {
    path: "/admin/user",
    name: "admin-user",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "all" */ "../views/AdminUserView.vue"),
  },
  {
    path: "/admin/userroles",
    name: "admin-userroles",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
    },
    component: () => import(/* webpackChunkName: "all" */ "../views/AdminUserRolesView.vue"),
  },
  {
    path: "/admin/warehouse",
    name: "admin-warehouse",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "all" */ "../views/AdminWarehouseView.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      requiresAuth: false,
      requiresPermission: false,
    },
    component: () => import(/* webpackChunkName: "all" */ "../views/AppLoginView.vue"),
  },
  {
    path: "/app-logout",
    name: "logout",
    meta: {
      requiresAuth: false,
      requiresPermission: false,
    },
    component: () => import(/* webpackChunkName: "all" */ "../views/AppLogoutView.vue"),
  },
  {
    path: "/user-settings",
    name: "user-settings",
    meta: {
      requiresAuth: false,
      requiresPermission: false,
    },
    component: () => import(/* webpackChunkName: "all" */ "../views/UserSettingsView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("auth/checkToken");
  const loggedIn = store.getters["auth/isLoggedIn"];

  //redirect to login
  if (to.meta.requiresAuth && !loggedIn) {
    return next({
      path: "/login",
      query: { returnUrl: to.path },
    });
  }

  if (!to.meta.requiresAuth) {
    //to proper redirect
    next();
  }

  let hasPermission = store.getters["auth/hasReadPermission"](to.name);

  if (!hasPermission) {
    hasPermission = store.getters["auth/hasReadPermission"](to.meta?.parent);
  }

  if (to.meta.requiresPermission && hasPermission && loggedIn) {
    next();
  }

  if (to.meta.requiresPermission && !hasPermission && loggedIn) {
    let firstReadPermission = store.getters["auth/findFirstReadPermission"];
    next(routes.find((r) => r.name === firstReadPermission.name));
  }
});

export default router;
