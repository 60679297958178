import WarehouseRepository from "./WarehouseRepository";
import UserRepository from "./UserRepository";
import AppRepository from "./AppRepository";
import LoginRepository from "./LoginRepository";
import AdminRepository from "./AdminRepository";
import CockpitRepository from "./CockpitRepository";
import ArticleRepository from "./ArticleRepository";
import GroupPickingRepository from "./GroupPickingRepository";

const repositories = {
  warehouse: WarehouseRepository,
  user: UserRepository,
  app: AppRepository,
  login: LoginRepository,
  admin: AdminRepository,
  cockpit: CockpitRepository,
  article: ArticleRepository,
  groupPicking: GroupPickingRepository
};
export default {
  get: (name) => repositories[name],
};
