import Repository from "@/repositories/RepositoryFactory";
import moment from "moment/moment";
import {replaceOrAppend} from "@/service/ArrayUtil";

const ArticleRepository = Repository.get("article");
const CockpitRepository = Repository.get("cockpit");
const WarehouseRepository = Repository.get("warehouse");

export default {
    namespaced: true,
    state: {
        stockInfo: [],
        selectedArticleMasterData: null,
        selectedArticlePlaceStockData: null,
        selectedArticlePlaceMovementData: null
    },
    getters: {
        getStockInfo(state) {
            return state.stockInfo;
        },
        getSelectedArticleMasterData(state) {
            return state.selectedArticleMasterData;
        },
        getSelectedArticlePlaceStockData(state) {
            return state.selectedArticlePlaceStockData;
        },
        getSelectedArticlePlaceMovementData(state) {
            return state.selectedArticlePlaceMovementData;
        }
    },
    mutations: {
        setStockInfo(state, payload) {
            state.stockInfo = payload;
        },
        setArticleMasterData(state, payload) {
            state.selectedArticleMasterData = payload;
        },
        setArticlePlaceStockData(state, payload) {
            state.selectedArticlePlaceStockData = payload;
        },
        setArticlePlaceMovementData(state, payload) {
            state.selectedArticlePlaceMovementData = payload;
        },
        addRemainingStockIndexToSelectedArticle(state, payload) {
            const selected = state.selectedArticleMasterData;
            if (selected?.remainingStockIndexList === undefined || selected?.remainingStockIndexList?.length === 0) {
                selected.remainingStockIndexList = []
            }
            selected.remainingStockIndexList = replaceOrAppend(
                selected.remainingStockIndexList,
                payload,
                (a, b) => a.index === b.index);
        }
    },
    actions: {
        async loadStockInfo({commit}, {search}) {
            var warehouseIds = null;
            var warehousePlace = null;
            var itemCnr = null;
            var itemIndex = null;
            var size = null;
            if (search) {
                warehouseIds = search.warehouseIds ? search.warehouseIds : null;
                warehousePlace = search.warehousePlace ? search.warehousePlace : null;
                itemCnr = search.itemCnr ? search.itemCnr : null;
                itemIndex = search.itemIndex ? search.itemIndex : null;
                size = search.size ? search.size : 100;
            }
            return CockpitRepository.getStockInfo(warehouseIds, itemCnr, itemIndex, warehousePlace, size).then((data) => {
                var order = 0;
                data.forEach(d => {
                    d.order = order++;
                    d.lastMovement = moment(d.lastMovement).format("DD.MM.YY HH:mm")
                })
                commit("setStockInfo", data)
            })
        },
        async loadSelectedArticleMasterData({commit}, {articleId, articleIndex}) {
            return ArticleRepository.getArticle(articleId).then((data) => {
                data = {
                    selectedIndex: articleIndex,
                    ...data
                }
                commit("setArticleMasterData", data);
            })
        },
        async loadSelectedArticlePlaceStockData({commit}, {warehouseId, cnr}) {
            return WarehouseRepository.getWarehouseArticles(warehouseId, undefined, cnr)
                .then((data) => commit("setArticlePlaceStockData", data));
        },
        async loadSelectedArticlePlaceMovementData({commit}, {warehouseId, cnr}) {
            return WarehouseRepository.getWarehouseArticleBookings(warehouseId, undefined, cnr)
                .then(data => commit("setArticlePlaceMovementData", data));
        },
        // eslint-disable-next-line no-empty-pattern
        async saveRemainingStock({commit}, article) {
            let articleId = article.id;
            return ArticleRepository.saveRemainingStock(articleId, {
                index: article.selectedIndex,
                remainingStockIndex: article.remainingStockIndexList?.find(o => o.index === article.selectedIndex)
            }).then((response) => {
                if (response.status === 200) {
                    commit("addRemainingStockIndexToSelectedArticle", response.data);
                }
            });
        }
    },
    modules: {},
};
