import { createStore } from "vuex";
import auth from "@/store/modules/auth";
import warehouse from "@/store/modules/warehouse";
import groupPicking from "@/store/modules/groupPicking";
import cockpitArticle from "@/store/modules/cockpitArticle";

const store = createStore({
  modules: {
    auth,
    warehouse,
    groupPicking,
    cockpitArticle
  },

  actions: {
    async initialiseStore() {
      await this.dispatch("auth/initialiseStore");
      await this.dispatch("warehouse/initWarehouses");
      if (localStorage.getItem("groupPickingStore")) {
        await this.dispatch(
          "groupPicking/initialiseStore",
          JSON.parse(localStorage.getItem("groupPickingStore"))
        );
      }
    },
  },
});

store.subscribe((mutation, state) => {
  const namespace = mutation.type.split("/")[0];
  switch (namespace) {
    /*case "auth":
      localStorage.setItem("authStore", JSON.stringify(state.auth));
      break;
    case "warehouse":
      localStorage.setItem("warehouseStore", JSON.stringify(state.warehouse));
      break;*/
    case "groupPicking":
      localStorage.setItem(
        "groupPickingStore",
        JSON.stringify(state.groupPicking)
      );
      break;
  }
});

export default store;
