<template>
    <div class="btn-group">
        <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static"
            aria-expanded="false">
            <font-awesome-icon class="text-black pe-1" icon="fa-solid fa-user" />
            {{ this.shortUser }}
        </button>
        <div class="dropdown-menu dropdown-menu-end">
            <div>
                <router-link class="dropdown-item" to="/user-settings" v-if="$store.getters['auth/isLoggedIn']">
                    <font-awesome-icon class="text-black pe-1" icon="fa-solid fa-gear" />
                    {{ $t('userSettings') }}
                </router-link>
            </div>
            <div class="ms-3"><language-switcher></language-switcher></div>
            <div class="dropdown-divider"></div>
            <router-link class="dropdown-item" aria-current="page" to="/app-logout"
                v-if="$store.getters['auth/isLoggedIn']">
                <font-awesome-icon class="text-black pe-1" icon="fa-solid fa-right-from-bracket" />
                {{ $t('logout') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
export default {
    components: {
        LanguageSwitcher
    },
    async mounted() {
        this.user = this.$store.getters['auth/user'];
    },
    computed: {
        shortUser() {
            let user = this.$store.getters['auth/user'];
            if (Object.keys(user).length > 0) {
                return `${user.firstName.substring(0, 1)}${user.lastName.substring(0, 4)}`
            }
            return "";
        }
    }
}

</script>

<style></style>