import Client from "./clients/SecureWarehouseClient";
const resource = "/article";
export default {
    async getArticles() {

    },
    async getArticle(id) {
        if(!id) {
            return Promise.resolve();
        }
        return Client.get(`${resource}/${id}`).then((response) => {
            return response.data;
        })
    },
    async saveRemainingStock(articleId, remainingStock) {
        const encodedIndex = encodeURIComponent(remainingStock.index);
        return Client.put(
            `${resource}/${articleId}/remainingStockIndex/${encodedIndex}`,
            remainingStock.remainingStockIndex);

    }
}