import axios from "axios";

const baseDomain = "";
const baseURL = `${baseDomain}/api`;

function loadHeader() {
  const storage = localStorage.getItem("user");
  if (storage) {
    let user = JSON.parse(storage);
    return user.token;
  }
}

const client = axios.create({
  baseURL,
});

client.interceptors.request.use(async (request) => {
  try {
    const token = loadHeader();
    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }
    if (localStorage.getItem("acceptLanguage")) {
      request.headers["Accept-Language"] =
        localStorage.getItem("acceptLanguage");
    }
  } catch (e) {
    // TODO
  }
  return request;
});

export default client;
