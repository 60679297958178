import Repository from "@/repositories/RepositoryFactory";

const GroupPickingRepository = Repository.get("groupPicking");

export default {
    namespaced: true,
    state: {
        pickingOrders: [],
        pickingOrderProposals: [],
        selectedWarehouse: {},
        defaultPickingOrderSort: [
            {shortName: "place", translateName: "sort.place"},
            {shortName: "articleNumber", translateName: "sort.articleNumber"},
        ],
    },
    getters: {
        selectedWarehouse(state) {
            return state.selectedWarehouse;
        },
        getPickingOrderSort(state) {
            return state.defaultPickingOrderSort;
        },
        getPickingOrderById: (state) => (id) => {
            return state.pickingOrders.find(
              (o) =>
                  o.warehouseId == state.selectedWarehouse.id &&
                  o.id == id
          );
        },
        getPickingOrderByPickingOrderNumber: (state) => (pickingOrderNumber) => {
            return state.pickingOrders.find(
                (o) =>
                    o.n == pickingOrderNumber &&
                    o.warehouseId == state.selectedWarehouse.id
            );
        },
        getPickingOrders(state) {
            if(!state.selectedWarehouse?.id) {
                return [];
            }
            let orders = state.pickingOrders
                .filter(
                (o) => {
                    return o.warehouseId == state.selectedWarehouse.id;
                }
            );
            return orders;
        },
        hasNotEnoughStock: (state, getters) => (id) => {
            if (
                getters
                    .getPickingOrderById(id)
                    .e.find((item) => item.m >= item.inventory) == null
            ) {
                return false;
            } else {
                return true;
            }
        },
        orderPickingStarted: (state, getters) => (id) => {
            if (
                getters
                    .getPickingOrderById(id)
                    .pickingStartTime
            ) {
                return true;
            } else {
                return false;
            }
        },
        getPickingOrderItem:
            (state, getters) =>
                ({id, index}) => {
                    return getters
                        .getPickingOrderById(id)
                        .e.find((item) => item.index == index);
                },
    },
    mutations: {
        setWarehouse(state, warehouse) {
            state.selectedWarehouse = warehouse;
        },
        setPickingOrders(state, pickingOrders) {
          state.pickingOrders = pickingOrders;
        },
        addPickingOrder(state, pickingOrder) {
            state.pickingOrders.push(pickingOrder);
        },
        addPickingOrderProposal(state, pickingOrderProposal) {
            let found = state.pickingOrderProposals.find((o) => {
                return (
                    o.warehouseId == state.selectedWarehouse.id &&
                    o.n == pickingOrderProposal.n
                );
            });
            if (found) {
                Object.assign(found, pickingOrderProposal);
            } else {
                state.pickingOrderProposals.push(pickingOrderProposal);
            }
        },
        replaceStore(state, initStore) {
            state.pickingOrders = initStore.pickingOrders;
            state.pickingOrderProposals = initStore.pickingOrderProposals;
            state.selectedWarehouse = initStore.selectedWarehouse;
        },
        updatePickingOrder(state, {id, pickingOrder}) {
            let found = state.pickingOrders.find(
                (o) =>
                    o.id == id &&
                    o.warehouseId == state.selectedWarehouse.id
            );
            Object.assign(found, pickingOrder);
        },
        deletePickingOrderByPickingOrderNumber(state, {pickingOrderNumber}) {
            state.pickingOrders.splice(
                state.pickingOrders.findIndex(
                    (pickingOrder) =>
                        pickingOrder.n == pickingOrderNumber &&
                        pickingOrder.warehouseId == state.selectedWarehouse.id
                ),
                1
            );
        },
        deletePickingOrder(state, {id}) {
            state.pickingOrders.splice(
                state.pickingOrders.findIndex(
                    (pickingOrder) =>
                        pickingOrder.id == id &&
                        pickingOrder.warehouseId == state.selectedWarehouse.id
                ),
                1
            );
        },
        deletePickingOrderProposal(state, {pickingOrderNumber}) {
            state.pickingOrderProposals.splice(
                state.pickingOrderProposals.findIndex(
                    (proposal) =>
                        (proposal.n =
                            pickingOrderNumber && proposal.warehouseId == state.selectedWarehouse.id)
                ),
                1
            );
        },
        deletePickingOrderProposalByPickingOrderNumber(
            state,
            {pickingOrderNumber}
        ) {
            state.pickingOrderProposals.splice(
                state.pickingOrderProposals.findIndex(
                    (proposal) =>
                        proposal.n == pickingOrderNumber &&
                        proposal.warehouseId == state.selectedWarehouse.id
                ),
                1
            );
        },
    },
    actions: {
        async loadPickingOrders({commit, getters, rootGetters}) {
            if(!getters.selectedWarehouse?.id) {
                return Promise.resolve();
            }
            let orderState = "created";
            let userId = rootGetters['auth/user']?.id;
            let warehouse = getters.selectedWarehouse?.id;
            return GroupPickingRepository
                .getPickingOrders(warehouse, userId, orderState)
                .then((response) => {
                    commit("setPickingOrders", response?.data);
                });
        },
        async setWarehouse({commit, dispatch}, warehouse) {
            commit("setWarehouse", warehouse);
            return dispatch("loadPickingOrders", warehouse);
        },
        async readPickingOrder({getters, dispatch}, {amount, pickingOrder}) {
            pickingOrder.warehouseId = getters.selectedWarehouse.id;
            pickingOrder.amount = amount;
            pickingOrder.e = pickingOrder.e.map((item, index) => ({
                index: index,
                ...item,
                m: item.m * amount
            }));
            if (!getters.getPickingOrderByPickingOrderNumber(pickingOrder.n)) {
                dispatch("addPickingOrder", pickingOrder);
            }
        },
        async addPickingOrder({getters, commit}, pickingOrder) {
            return GroupPickingRepository.createPickingOrder(
                getters.selectedWarehouse.id,
                pickingOrder)
                .then((pickingOrderResponse) => {
                    commit("addPickingOrder", pickingOrderResponse);
                })
        },
        async initialiseStore({commit}, initStore) {
            return commit("replaceStore", initStore);
        },
        async checkStock({getters, commit, dispatch}, {id}) {
            let pickingOrder =
                getters.getPickingOrderById(id);
            return GroupPickingRepository.addPickingOrder(
                getters.selectedWarehouse.id,
                pickingOrder
            ).then((pickingOrderProposal) => {
                pickingOrderProposal.e.forEach((pickingItem) => {
                    pickingItem.m = pickingItem.m.sort((a, b) =>
                        a.place.localeCompare(b.place)
                    );
                });
                commit("addPickingOrderProposal", pickingOrderProposal);
                dispatch("addInventoryInformationToPickingOrder", {
                    pickingOrder,
                    pickingOrderProposal,
                });
            });
        },
        async addInventoryInformationToPickingOrder(
            {commit},
            {pickingOrder, pickingOrderProposal}
        ) {
            pickingOrder.e = pickingOrder.e
                .map((pickItem) => ({
                    ...pickItem,
                    inventory: pickingOrderProposal.e.find(
                        (proposalItem) =>
                            proposalItem.t == pickItem.t && proposalItem.i == pickItem.i
                    ).m,
                    inventoryAmount: pickingOrderProposal.e
                        .find(
                            (proposalItem) =>
                                proposalItem.t == pickItem.t && proposalItem.i == pickItem.i
                        )
                        .m.map((inv) => inv.amount)
                        .reduce((prev, next) => prev + next, 0),
                }))
                .sort((a, b) => a.inventoryAmount - b.inventoryAmount);
            commit("updatePickingOrder", {
                id: pickingOrder.id,
                pickingOrder,
            });
        },
        async sortPickingOrderBy(
            {getters, commit},
            {id, sortBy, order}
        ) {
            let pickingOrder = getters.getPickingOrderById(id);
            if (sortBy === "place") {
                pickingOrder.e = pickingOrder.e.sort((a, b) => {
                        let left = a.inventory[0]?.place;
                        let right = b.inventory[0]?.place;
                        if(!left) {
                            return -1;
                        }
                        if(!right) {
                            return 1;
                        }
                        return left.localeCompare(right)
                    }
                );
            }
            if (sortBy === "articleNumber") {
                pickingOrder.e = pickingOrder.e.sort((a, b) => a.t?.localeCompare(b.t));
            }
            if (order === "desc") {
                pickingOrder.e = pickingOrder.e.reverse();
            }
            commit("updatePickingOrder", {id: pickingOrder.id, pickingOrder});
        },
        async deletePickingOrderById({getters, commit}, id) {
            let pickingOrder = getters.getPickingOrderById(id);

            GroupPickingRepository.cancelPickingOrder(
                pickingOrder?.warehouseId,
                id
            ).then();

            commit("deletePickingOrderProposalByPickingOrderNumber", {
                pickingOrderNumber: pickingOrder.n,
            });
            commit("deletePickingOrderByPickingOrderNumber", {pickingOrderNumber: pickingOrder.n});
        },
        async finishPickingOrderByPickingOrderNumber({getters, commit}, id) {
            let pickingOrder = getters.getPickingOrderByPickingOrderNumber(id);

            GroupPickingRepository.completePickingOrder(
                pickingOrder?.warehouseId,
                id
            ).then();

            commit("deletePickingOrderProposalByPickingOrderNumber", {
                pickingOrderNumber: pickingOrder.n,
            });
            commit("deletePickingOrderByPickingOrderNumber", {pickingOrderNumber: pickingOrder.n});
        },
        async startPickingOrder({getters, dispatch}, id) {
            let pickingOrder = getters.getPickingOrderById(id);
            if (!pickingOrder.pickingStartTime) {
                pickingOrder.pickingStartTime = Date.now();
                pickingOrder.e = pickingOrder.e.map((pickItem) => ({
                    ...pickItem,
                    picking: {
                        pickedAmount: 0,
                    },
                }));
                dispatch("updatePickingOrder", {id, pickingOrder});
            }
        },
        async incrementPickedAmount(
            {getters, dispatch},
            {id, index, pickedAmount}
        ) {
            let pickingOrder =
                getters.getPickingOrderById(id);
            pickingOrder.e.find((item) => item.index == index).picking.pickedAmount +=
                pickedAmount;
            dispatch("updatePickingOrder", {id, pickingOrder});
        },
        async decrementPickedAmount(
            {getters, dispatch},
            {id, index, discardedAmount}
        ) {
            let pickingOrder =
                getters.getPickingOrderById(id);
            pickingOrder.e.find((item) => item.index == index).picking.pickedAmount -=
                discardedAmount;
            dispatch("updatePickingOrder", {id, pickingOrder});
        },
        async updatePickingOrder(
            {getters, commit},
            {id, pickingOrder}) {
            GroupPickingRepository.updatePickingOrder(
                getters.selectedWarehouse.id,
                pickingOrder.id,
                pickingOrder
            ).then(() => {
                commit("updatePickingOrder", {id, pickingOrder});
            });
        }
    },
    modules: {},
};
