<template>
    <div class="locale-changer">
        <font-awesome-icon class="text-black pe-1" icon="fa-solid fa-earth-europe" />
        {{ $t('language') }} <select class="selectpicker" v-model="$i18n.locale" @change="onChangeLanguage">
            <option v-for="locale in this.locales" :key="`locale-${locale}`" :value="locale">
                {{ localeFlag(locale) }}
            </option>
        </select>
    </div>
</template>

<script>

export default {
    computed: {
        locales() {
            return ['de', 'sr', 'bs', 'en'];
        }
    },
    methods: {
        onChangeLanguage(evt) {
            this.$store.commit('auth/setLanguage', evt.target.value);
        },
        localeFlag(locale) {
            if (locale === 'de') {
                return '🇦🇹';
            }
            if (locale === 'sr') {
                return '🇷🇸';
            }
            if (locale === 'bs') {
                return '🇧🇦';
            }
            if (locale === 'en') {
                return '🇬🇧';
            }

        }
    }
}

</script>

<style scoped>
select {

    border-width: 0;
    background-color: grey;
    color: white;
    outline: none;
    border-radius: 4px;
    border: 1px solid #AAAAAA;
}
</style>