import Client from "./clients/SecureWarehouseClient";
const resource = "/user";

export default {
  async getUserByBarcode(barcode) {
    if (barcode.length == 0) {
      return Promise.resolve("");
    }
    let alternateResponse = [{ firstName: "Benutzer nicht vorhanden" }];

    return Client.get(`${resource}?barcode=${barcode}`)
      .then((response) => {
        return Object.keys(response.data).length != 0
          ? response.data[0]
          : alternateResponse[0];
      })
      .catch(() => {
        return alternateResponse;
      });
  },
  async getUserById(id) {
    return Client.get(`${resource}/${id}`).then((response) => {
      return response.data;
    });
  },
  async getUserByUserId(userId) {
    if (userId.length == 0) {
      return Promise.resolve("");
    }

    let alternateResponse = [{ firstName: "Benutzer nicht vorhanden" }];

    return Client.get(`${resource}?userId=${userId}`)
      .then((response) => {
        return Object.keys(response.data).length != 0
          ? response.data[0]
          : alternateResponse[0];
      })
      .catch(() => {
        return alternateResponse;
      });
  },
  async getUserRoleOfUser(id) {
    if (!id) {
      return Promise.resolve("");
    }

    return Client.get(`${resource}/${id}/role`).then((response) => {
      return response.data;
    });
  },
  async changePassword(id, changePassword) {
    return Client.post(`${resource}/${id}/password`, changePassword);
  },
};
