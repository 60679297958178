import en from "./en.json";
import de from "./de.json";
import sr from "./sr.json";
import bs from "./bs.json";

export const defaultLocale = "de";

export const languages = {
  de: de,
  sr: sr,
  bs: bs,
  en: en,
};
