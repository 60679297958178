import Client from "./clients/SecureWarehouseClient";
const resource = "/admin";

export default {
  async getWarehouses() {
    return Client.get(`${resource}/warehouse?size=500`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  },
  async createWarehouse(warehouse) {
    return Client.post(`${resource}/warehouse`, warehouse);
  },
  async updateWarehouse(warehouse) {
    return Client.post(`${resource}/warehouse/${warehouse.id}`, warehouse);
  },
  async createUser(user) {
    return Client.post(`${resource}/user`, user);
  },
  async updateUser(user) {
    return Client.post(`${resource}/user/${user.id}`, user);
  },
  async getUsers() {
    return Client.get(`${resource}/user`);
  },
  async getUserRoles() {
    return Client.get(`${resource}/user/role`).then((response) => {
      return response.data;
    });
  },
  async createUserRole(userRole) {
    return Client.post(`${resource}/user/role`, userRole).then((response) => {
      return response.data;
    });
  },
  async updateUserRole(id, userRole) {
    return Client.post(`${resource}/user/role/${id}`, userRole).then(
      (response) => {
        return response.data;
      }
    );
  },
  async getUserRoleTemplate() {
    return Client.get(`${resource}/user/role/template`).then((response) => {
      return response.data;
    });
  },
};
