import Client from "./clients/SecureWarehouseClient";

const resource = "/cockpit";

export default {
    async getStockInfo(warehouseIds, itemCnr, itemIndex, warehousePlaceShortName, size) {
        let queryArray = [];
        let queryStr = "";
        if (warehouseIds && warehouseIds.length > 0) {
            queryArray.push("warehouseIds=" + warehouseIds.join(","));
        }
        if (itemCnr) {
            queryArray.push("cnr=" + itemCnr);
        }
        if (itemIndex) {
            queryArray.push("itemIndex=" + itemIndex);
        }
        if (warehousePlaceShortName) {
            queryArray.push("warehousePlaceShortName="+warehousePlaceShortName);
        }
        if (size) {
            queryArray.push("size="+size)
        }
        if (queryArray.length !== 0) {
            queryStr += "?";
            queryStr += queryArray.join("&");
        }
        return Client.get(`${resource}/article${queryStr}`).then((response) => {
            return response.data;
        })
    },
};
