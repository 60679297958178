import Client from "./clients/SecureWarehouseClient";
const resource = "/warehouse";

export default {
  async getWarehouses() {
    return Client.get(`${resource}`).then((response) => {
      return response.data;
    });
  },
  async addPlace(warehouseId, request) {
    return Client.post(`${resource}/${warehouseId}/place`, request).then(
      (response) => {
        return response;
      }
    );
  },
  async getArticleByItemCnr(itemCnr) {
    if (itemCnr.length == 0) {
      return Promise.resolve("");
    }
    let alternateResponse = { name: "Unbekannter Artikel" };
    return Client.get(`${resource}/article?itemCnr=${itemCnr}`)
      .then((response) => {
        return Object.keys(response.data[0]).length != 0
          ? response.data[0]
          : alternateResponse;
      })
      .catch(() => {
        return alternateResponse;
      });
  },
  async getWarehouseArticleBookings(
    warehouseId,
    size = 100,
    articleCnr,
    shortName
  ) {
    if (warehouseId == null) {
      return Promise.resolve([]);
    }
    let searchString = `size=${size}`;
    if (articleCnr) {
      searchString += `&articleCnr=${articleCnr}`;
    }
    if (shortName) {
      searchString += `&shortName=${shortName}`;
    }
    return Client.get(`${resource}/${warehouseId}/bookings?${searchString}`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  },
  async searchItemBookings(warehouseId, search) {
    if (warehouseId == null) {
      return Promise.resolve([]);
    }
    let encodedSearchString = encodeURIComponent(
      this._extendSearchString(search)
    );
    let query = `search=${encodedSearchString}`;
    return Client.get(`${resource}/${warehouseId}/bookings?${query}`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  },
  async searchItems(warehouseId, search) {
    if (warehouseId == null) {
      return Promise.resolve([]);
    }
    let encodedSearchString = encodeURIComponent(
      this._extendSearchString(search)
    );
    let query = `search=${encodedSearchString}`;
    return Client.get(`${resource}/${warehouseId}/items?${query}`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  },
  async getWarehouseArticles(warehouseId, size = 100, articleCnr, shortName) {
    if (warehouseId == null) {
      return Promise.resolve([]);
    }
    let searchString = `size=${size}`;
    if (articleCnr) {
      searchString += `&articleCnr=${articleCnr}`;
    }
    if (shortName) {
      searchString += `&shortName=${shortName}`;
    }
    return Client.get(`${resource}/${warehouseId}/items?${searchString}`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  },
  _extendSearchString(search) {
    search
      //.replaceAll("*", "\\*")
      .replaceAll("_", "\\_")
      .replaceAll("-", "\\-")
      .replaceAll("+", "\\+")
      .replaceAll("(", "\\(")
      .replaceAll(")", "\\)")
      .replaceAll(":", "\\:");
    return `${search}`;
  },
};
