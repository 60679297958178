import Client from "./clients/WarehouseClient";
const resource = "/app";

export default {
  async getVersion() {
    return Client.get(`${resource}/version`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return 0;
      });
  },
  async getAppInfo() {
    return Client.get(`${resource}/info`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return 0;
      });
  },
};
