<template>
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light" :class="this.showProfile ? 'test' : 'bg-light'">
    <div class="container-fluid">
      <button class="navbar-toggler border-0 order-0 shadow-none" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false"
        aria-label="Toggle navigation" v-if="$store.getters['auth/isLoggedIn']">
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navbar-brand order-1" href="#">GMT {{ this.showProfile }}{{ this.appVersion }}</a>
      <div class="order-2 order-lg-4">
        <user-profile-icon></user-profile-icon>
      </div>
      <div class="collapse order-3 navbar-collapse" :class="!visible ? 'collapse' : ''" id="navbarTogglerDemo03">
        <ul class="navbar-nav me-auto pe-md-3 pb-md-3 pt-md-2 p-lg-0 mb-lg-0">
          <li class="nav-item" v-if="$store.getters['auth/hasReadPermission']('addArticle')">
            <router-link class="nav-link active" aria-current="page" to="/" @click="visible = !visible">
              <font-awesome-icon class="text-black fa-2x" icon="fa-solid fa-dolly" />
              {{ $t('app.createArticle') }}
            </router-link>
          </li>
          <li class="nav-item" v-if="$store.getters['auth/hasReadPermission']('removeArticle')">
            <router-link class="nav-link active" aria-current="page" to="/remove-item" @click="visible = !visible">
              <font-awesome-icon class="text-black fa-2x fa-flip-horizontal" icon="fa-solid fa-dolly" />
              {{ $t('app.removeArticle') }}
            </router-link>
          </li>
          <li class="nav-item" v-if="$store.getters['auth/hasReadPermission']('groupPicking')">
            <router-link class="nav-link active" aria-current="page" to="/group-picking" @click="visible = !visible">
              <font-awesome-icon class="text-black fa-2x" icon="fa-solid fa-cubes" />
              {{ $t('groupPicking') }}
            </router-link>
          </li>
          <li class="nav-item dropdown" v-if="$store.getters['auth/hasReadPermission']('cockpit')">
            <a class="nav-link active dropdown-toggle" href="#" id="navbarScrollingDropdown2" role="button"
               data-bs-toggle="dropdown" aria-expanded="false">
              <font-awesome-icon class="text-black fa-2x" icon="fa-solid fa-warehouse" />
              {{ $t('cockpit') }}
            </a>
            <ul class="dropdown-menu me-3 mb-3" aria-labelledby="navbarScrollingDropdown2">
              <li v-if="$store.getters['auth/hasReadPermission']('warehouseOverview')">
                <router-link class="dropdown-item" to="/cockpit/warehouse-overview" @click="visible = !visible">
                  <font-awesome-icon class="text-black" icon="fa-solid fa-warehouse" />
                  {{ $t('warehouseOverview') }}
                </router-link>
              </li>
              <li v-if="$store.getters['auth/hasReadPermission']('articleOverview')">
                <router-link class="dropdown-item" to="/cockpit/article-overview" @click="visible = !visible">
                  <font-awesome-icon class="text-black" icon="fa-solid fa-warehouse" />
                  {{ $t('articleOverview') }}
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown" v-if="$store.getters['auth/isAdmin']">
            <a class="nav-link active dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <font-awesome-icon class="text-black fa-2x" icon="fa-solid fa-gear" />
              {{ $t('app.admin') }}
            </a>
            <ul class="dropdown-menu me-3 mb-3" aria-labelledby="navbarScrollingDropdown">
              <li>
                <router-link class="dropdown-item" to="/admin/user" @click="visible = !visible">
                  <font-awesome-icon class="text-black" icon="fa-solid fa-user" />
                  {{ $t('app.createUser') }}
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/admin/userroles" @click="visible = !visible">
                  <font-awesome-icon class="text-black" icon="fa-solid fa-user-group" />
                  {{ $t('app.userroles') }}
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/admin/warehouse" @click="visible = !visible">
                  <font-awesome-icon class="text-black" icon="fa-solid fa-warehouse" />
                  {{ $t('app.warehouse') }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<script>
import Repository from "@/repositories/RepositoryFactory";
import UserProfileIcon from "@/components/user/UserProfileIcon";
const AppRepository = Repository.get("app");

export default {
  components: {
    UserProfileIcon
  },
  data() {
    return {
      appVersion: "0",
      profile: "",
      visible: false
    }
  },
  beforeCreate() {
    AppRepository.getAppInfo()
      .then((appInfo) => {
        this.appVersion = appInfo.version;
        this.profile = appInfo.profile;
      });

    if (localStorage.getItem("acceptLanguage")) {
      this.$i18n.locale = localStorage.getItem("acceptLanguage");
    }

    this.$store.dispatch('initialiseStore');
  },
  computed: {
    showProfile() {
      var showProfile;
      this.profile !== "prod" ? showProfile = this.profile + " " : showProfile = "";
      return showProfile.toLocaleUpperCase();
    }
  },
}
</script>

<style lang="scss">
html {
  box-sizing: border-box;
  overflow-y: scroll;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}

#navbar {
  z-index: 1000;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.form-control:focus {
  outline: 0;
  box-shadow: none;
  border-bottom: 2px solid green;
}

*:focus {
  outline: 0;
  box-shadow: none;
}

.form-select:focus {
  outline: 0;
  box-shadow: none;
  border-bottom: 2px solid green;
}

.form-select {
  border-radius: 0;
  border-width: 2px;
  border-color: transparent transparent var(--bs-secondary);
  padding: 10px;
}

.actionbtn {
  border: 2px solid var(--bs-primary);
}

.actionbtn:checked {
  border: 2px solid var(--bs-primary);
}

.actionbtn:hover {
  border: 2px solid var(--bs-primary);
}

.test {
  background-color: rgb(128, 174, 223) !important;
}

.custom-nav-button {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.navbar-brand {
  width: 150px;
  height: 40px;
}

.bottom-container {
  height: 50px;
  width: 100%;
  box-shadow: rgba(0, 0, 25, 0.25) 0px 0px 25px;
  background-color: white;
}

.btn-no-style, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@include media-breakpoint-up(sm) {
  .bottom-container {
    height: 70px;
    width: 100%;
    box-shadow: rgba(0, 0, 25, 0.25) 0px 0px 25px;
    background-color: white;
  }
}

.slight-grey-bg {
  background-color: #F9F9F9;
}

@media screen and (max-width:767px) {
  .navbar .navbar-collapse {
    position: absolute;
    top: 56px;
    left: 0px;
    z-index: 5;
    background-color: var(--bs-light);
    padding-left: 20px;
    width: 100%;
  }
}

</style>
